.form-container {
  height: 72vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

h1 {
  text-align: center;
  transform: uppercase;
  color: black;
}

h4 {
  transform: uppercase;
  color: black;
}

h5 {
  color: black;
}

h6 {
  color: black;
}

hr {
  border-top: 5px solid #39b392;
  margin-right: 45px;
}

.form-control {
  width: 90%;
  padding: 10px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 1em;
  font-family: inherit;

  color: #c0c5ce;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: transparent !important;
  outline: none;
  border-radius: 0px;
}

.form-control:focus {
  color: #39b392;
  box-shadow: none;
}

label {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  color: black;
  /* font-weight: 300; */
  font-size: 16px;
  font-weight: bold;
}

.add-field {
  color: #39b392;
  border: 1px solid #39b392;
  background: transparent;
}

.remove-field {
  color: #39b392;
  border: 1px solid #39b392;
  background: transparent;
}

.add-field:hover {
  background-color: #39b392;
  color: white;
  transition: 0.5s;
}

.remove-field:hover {
  background-color: #39b392;
  color: white;
  transition: 0.5s;
}

.styled-btn {
  background: linear-gradient(40deg, #206653, #56c9aa);
  border: 1px solid white;
  color: white;
  border-radius: 50px;
  padding: 10px;
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
}

.styled-btn:hover {
  background: linear-gradient(40deg, #56c9aa, #206653);
}

.img-template {
  cursor: pointer;
}

.templates-container {
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card-item {
  background-color: transparent;
}

.user-item:hover {
  background: linear-gradient(40deg, #56c9aa, #206653);
}

.clinic-upload-main-view {
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  flex: 1;
  display: flex;
  margin-bottom: 0.1rem;
}

.image-cirle-view {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  /* background-color: header-background-color; */
}

.image {
  /* flex: 1; */
  display: 'flex';
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.file-operation-text-container {
  flex: 1;
  display: flex;
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.image-input {
  border-radius: 50%;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  height: 6.5rem;
  width: 6.5rem;
  cursor: pointer;
}