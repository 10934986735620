.footer {
  position: fixed;
  left: 0;
  padding: 19px;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: center;
}

.btnn {
  /*background: linear-gradient(to right, #f26798 0%, #6c5ce7 100%);*/
  /*background: linear-gradient( 40deg,#206653,#56c9aa );*/
  background-color: transparent;
  border: 1px solid #39b392;
  color: #39b392;
  border-radius: 50px;
  width: 80px;
  padding: 2px;
  margin-left: 60px;
  margin-right: 50px;
}

.btnn:hover {
  background-color: #39b392;
  color: white;
  transition: 0.5s;
}

.btnp {
  /*background: linear-gradient(to right, #f26798 0%, #6c5ce7 100%);*/
  /*background: linear-gradient( 40deg,#206653,#56c9aa );*/
  background-color: transparent;
  border: 1px solid #39b392;
  color: #39b392;
  border-radius: 50px;
  width: 80px;
  padding: 2px;
}

.btnp:hover {
  background-color: #39b392;
  color: white;
  transition: 0.5s;
}
.facolor{
  color: gray;
}
.social-buttons a {
  display: inline-flex;
  text-decoration: none;
  font-size: 18px;
  width: 60px;
  height: 60px;
  color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 8px;
}

.social-buttons a::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  background: linear-gradient(45deg, #22a6b3, #30336b);
  border-radius: 50%;
  z-index: -1;
  transition: 0.3s ease-in;
}

.social-buttons a:hover::before {
  transform: scale(0);
}

.social-buttons a i {
  transition: 0.3s ease-in;
}

.social-buttons a:hover i {
  /* background: linear-gradient(45deg, #22a6b3, #30336b); */
  -webkit-background-clip: text;
  /*-webkit-text-fill-color: transparent;*/
  transform: scale(2.2);
  background-clip: initial;
}

.social-buttons a:hover .fa-whatsapp {
  /*background-color: springgreen;*/
  color:springgreen;
}

.social-buttons a:hover .fa-envelope {
  /*background-color: #d93025;*/
  color:#d93025;
  
}
