.auth-page-container {
  background: linear-gradient(0deg, rgba(117, 91, 231, 0.541), rgba(116, 90, 231, 0.541)), url(../../images/bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Avenir" !important;
  margin: 0;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 0px;
  padding: 30px;
  
}
.form-label{
  color:black;
}
.main {
  padding: 30px;
  color: black;
}
.signin {
  color: black;
  z-index: 1;
  padding: 20px;
  padding-top: -20px;
  
}

.form-control {
  width: 100%;
  padding: 10px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 1em;
  font-family: inherit;
  color: #c0c5ce;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  outline: none;
  border-radius: 0px;
  padding: 20px;
}
.form-control:focus {
  box-shadow: none;
}
span {
  font-weight: 500px;
  font-size: 18px;
  color: #39b392;
  
}
.maincards{
  width:50%;
  background-color: #321fdb;
  font-family: "Avenir" !important;
}
.cardpadding{
  padding-left: 20px;
}
span:active,
span:hover {
  color: #39b392;
}
.btn1 {
  /*background: linear-gradient(to right, #f26798 0%, #6c5ce7 100%);*/
  border: 1px solid white;
  color: white;
  border-radius: 50px;
  padding: 8px;
  width: 80px;
  margin-top: 10px;
  display: block;
  background-color: #321fdb;
}
.btn1:hover,
.btn1:active {
  /*background: linear-gradient(to right, #f26798 0%, #6c5ce7 100%);
  background: linear-gradient(40deg, #56c9aa, #206653);*/
  background-color: #321fdb;
  border: 1px solid white;
  color: white;
}
@media screen and (max-width: 767px) {
  .main {
    padding: 0px;
    padding-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
    
  }
  .signin {
    padding: 0px;
    padding-top: 0px;
    
  }
  .auth-page-container {
    background-color: rgba(49, 4, 247, 0.541);
    font-family: "Avenir" !important;
    margin: 0;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 0px;
  padding: 0px;
  }
  .maincards{
    display: none;
  }
  .cardpadding{
    padding-left: 0px;
  }
}

.error-message {
  color: red;
  font-size: small;
  font-style: oblique;
}

.error-message-text {
  color: red;
  font-size: small;
  font-style: oblique;
}

.reset-button {
  /*background: linear-gradient(to right, #f26798 0%, #6c5ce7 100%);*/
  border: 1px solid white;
  color: white;
  border-radius: 4px;
  padding: 8px;
  /* width: 80px; */
  /* margin-top: 10px; */
  display: block;
  background-color: #321fda;
  margin-left: 50%;
}