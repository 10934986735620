.sidenav {
  /* height: 80vh; */
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  padding-top: 20px;
  /* border-right: 1px solid #202530; */
}

.sidenav .item {
  padding: 10px 12px 6px 16px;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding-left: 60px;
  color: black;
  cursor: pointer;
}

.sidenav .item:hover {
  color: #788396;
}
.completecolor{
  color: blue;
}
/* .button {
  background: linear-gradient(40deg, #206653, #56c9aa);
  border: 1px solid white;
  color: white;
  border-radius: 50px;
  padding: 10px;
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
  display: block;
}

.button:hover,
active {
  background: linear-gradient(40deg, #56c9aa, #206653);
  border: 1px solid white;
  color: white;
  border-radius: 50px;
  padding: 10px;
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
  display: block;
} */
