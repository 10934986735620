@font-face {
  font-family: "manual_calibri";
  src: url("../fonts/Calibri_Regular.ttf");
}

.pdf-viewer {
  height: 62vh;
  width: 100%;
  font-family: "manual_calibri" !important;
}
