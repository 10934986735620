body {
  /* background: #12c2e9;
  background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
  background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); */
  background-color: white;
  /* background-color:rgb(157, 87, 248); */
  font-family: "Avenir" !important;
  z-index: 0;
}

.App {
  overflow: hidden;
  padding-top: 18px;
  padding-bottom: 80px;
  height: 100vh;
}

.dashboard-container {
  border-top: 1px solid gray;
}
